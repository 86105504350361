<template>
  <div>
    <div class="content-already-registered" v-if="Object.keys(user).length == 0">
      <h2>{{ $t('register.already_registered') }}</h2>
      <div class="content-already-registered-body">
        <b-tabs v-model="tabIndex" content-class="content-tabs">
          <b-tab :title="$t('register.login')">
            <div class="content-login">
              <div class="content-login-itens">
                <div class="col-12">
                  <form-login class="form-login" @CreateAccount="tabIndex = $event"/>
                </div>
              </div>
            </div>
          </b-tab>
          <b-tab :title="$t('register.create_an_account')">
            <div class="content-create-account">
              <div class="content-create-account-itens">
                <div class="col-12">
                  <form-register class="form-register" @Login="tabIndex = $event"/>
                </div>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import FormLogin from "@/views/User/views/Login/FormLogin.vue";
import FormRegister from "@/views/User/views/Register/FormRegister.vue";

export default {
  data() {
    return {
      tabIndex: 0,
    }
  },
  components: {
    FormLogin,
    FormRegister,
  },
  computed: {
    ...mapGetters("User", ["user"]),
  },
};
</script>
<style lang="scss">
.nav-tabs {
  border: 0;
  .nav-item {
    width: 270px;
    @media (max-width: 767px){
      width: 150px;
    }
    @media (max-width: 359px){
      width: 130px;
    }
    &:first-child {
      margin-right: 30px;
      @media (max-width: 767px) {
        margin-right: 15px;
      }
    }
    .nav-link {
      padding: 0;
      border-style: none;
      border-radius: 0;
      height: 45px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--main-color);
      border: 1px solid #ebebeb;
      font-size: 16px;
      font-family: "Font Bold";
      color: var(--main-color4);
      text-transform: uppercase;
      text-decoration: none;
      transition: 500ms;
      margin-right: 30px;
      @media (max-width: 767px) {
        margin-right: 0px;
        margin-bottom: 30px;
        font-size: 12px;
      }
    }
    .active {
      height: 45px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #ececec;
      border: 1px solid #ececec;
      font-size: 16px;
      font-family: "Font Bold";
      color: var(--main-color4);
      text-transform: uppercase;
      text-decoration: none;
      transition: 500ms;
      @media (max-width: 767px) {
        font-size: 12px;
      }
    }
  }
}
.content-login {
  padding: 80px 0px;
  @media (max-width: 991px) {
    padding: 40px 0px;
  }
  &-itens {
    &-header {
      padding-bottom: 10px;
      border-bottom: 1px solid #ebebeb;
      h1 {
        font-size: 35px;
        font-family: "Font Bold";
        color: var(--main-color5);
        margin-bottom: 0;
        text-transform: uppercase;
        @media (max-width: 576px) {
          font-size: 28px;
        }
      }
    }
    &-body {
      .content-elements {
        &-header {
          text-align: center;
          margin-bottom: 30px;
          h2 {
            font-size: 35px;
            font-family: "Font Bold";
            color: var(--main-color5);
            margin-bottom: 0;
            @media (max-width: 576px) {
              font-size: 28px;
            }
          }
        }
        &-body {
          .content-input {
            text-align: left;
            margin-bottom: 30px;
            label {
              display: block;
              font-size: 16px;
              font-family: "Font Regular";
              color: var(--main-color4);
              margin-bottom: 5px;
              line-height: 1;
            }
            input {
              height: 50px;
              width: 100%;
              border: 1px solid #ebebeb;
              background: #fff;
              outline: none;
              font-size: 16px;
              font-family: "Font Regular";
              color: var(--main-color4);
              padding-left: 20px;
            }
          }
        }
      }
      .btn-create {
        height: 55px;
        width: 100%;
        background: var(--main-color5);
        border: 1px solid var(--main-color5);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        font-family: "Font Bold";
        color: var(--main-color);
        text-transform: uppercase;
        margin-bottom: 30px;
        transition: 500ms;
        &:hover,
        &:focus {
          background: var(--main-color);
          color: var(--main-color5);
        }
        @media (min-width: 992px) and (max-width: 1199px) {
          font-size: 16px;
          height: 50px;
        }
        @media (max-width: 359px) {
          font-size: 16px;
          height: 45px;
        }
      }
      .btns-div {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .btn-continue-shopping {
        display: flex;
        align-items: center;
        justify-content: center;
        background: 0;
        border: 0;
        font-size: 16px;
        font-family: "Font Bold";
        color: var(--main-color4);
        padding: 0;
        text-decoration: none;
        transition: 500ms;
        &:hover,
        &:focus {
          cursor: pointer;
          opacity: 0.8;
        }
        img {
          margin-right: 5px;
          @media (max-width: 576px) {
            max-width: 15px;
          }
        }
        svg {
          width: 20px;
          height: 20px;
          fill: var(--main-color4);
          margin-left: 5px;
          @media (max-width: 576px) {
            width: 15px;
            height: 15px;
          }
        }
        @media (max-width: 576px) {
          font-size: 12px;
        }
      }
    }
  }
}
.content-create-account {
  padding: 80px 0px;
  @media (max-width: 991px) {
    padding: 40px 0px;
  }
  &-itens {
    &-header {
      padding-bottom: 10px;
      border-bottom: 1px solid #ebebeb;
      h1 {
        font-size: 35px;
        font-family: "Font Bold";
        color: var(--main-color5);
        margin-bottom: 0;
        text-transform: uppercase;
        @media (max-width: 576px) {
          font-size: 28px;
        }
      }
    }
    &-body {
      .content-elements {
        &-header {
          text-align: center;
          margin-bottom: 30px;
          h2 {
            font-size: 35px;
            font-family: "Font Bold";
            color: var(--main-color5);
            margin-bottom: 0;
            @media (max-width: 576px) {
              font-size: 28px;
            }
          }
        }
        &-body {
          .content-input {
            text-align: left;
            margin-bottom: 30px;
            label {
              display: block;
              font-size: 16px;
              font-family: "Font Regular";
              color: var(--main-color4);
              margin-bottom: 5px;
              line-height: 1;
            }
            input,
            select {
              height: 50px;
              width: 100%;
              border: 1px solid #ebebeb;
              background: #fff;
              outline: none;
              font-size: 16px;
              font-family: "Font Regular";
              color: var(--main-color4);
              padding-left: 20px;
              appearance: none;
              -moz-appearance: none;
              -webkit-appearance: none;
            }
            &-select {
              position: relative;
              img {
                position: absolute;
                right: 20px;
                top: 50%;
                transform: translateY(-50%);
                pointer-events: none;
              }
            }
            .help-block {
              font-size: 12px;
              font-family: "Font Light";
              color: var(--main-color4);
            }
          }
        }
      }
      .start-field > input[type="checkbox"]:checked:before {
        content: "";
        height: 100%;
        width: 100%;
        background: url("../../../../assets/img/checkout/check.png");
        background-position: center !important;
        background-repeat: no-repeat !important;
      }
      .start-field > input[type="checkbox"]:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0px;
        border: 0;
      }
      .start-field > input[type="checkbox"] {
        position: relative;
        height: 30px;
        width: 30px;
        border-radius: 0px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        outline: 0 !important;
        margin: 0;
        margin-right: 20px;
      }
      .start-field {
        display: flex;
        align-items: center;
        label {
          margin-bottom: 0 !important;
          a {
            text-decoration: underline;
            // margin: 0px 7px;
            transition: 500ms;
            &:hover,
            &:focus {
              cursor: pointer;
              opacity: 0.6;
              text-decoration: underline;
            }
          }
        }
      }
      .btn-create {
        height: 55px;
        width: 100%;
        background: var(--main-color5);
        border: 1px solid var(--main-color5);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        font-family: "Font Bold";
        color: var(--main-color);
        text-transform: uppercase;
        margin-bottom: 30px;
        transition: 500ms;
        &:hover,
        &:focus {
          background: var(--main-color);
          color: var(--main-color5);
        }
        @media (min-width: 992px) and (max-width: 1199px) {
          font-size: 16px;
          height: 50px;
        }
        @media (max-width: 359px) {
          font-size: 16px;
          height: 45px;
        }
      }
      .btns-div {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .btn-continue-shopping {
        display: flex;
        align-items: center;
        justify-content: center;
        background: 0;
        border: 0;
        font-size: 16px;
        font-family: "Font Bold";
        color: var(--main-color4);
        padding: 0;
        text-decoration: none;
        transition: 500ms;
        &:hover,
        &:focus {
          cursor: pointer;
          opacity: 0.8;
        }
        img {
          margin-right: 5px;
          @media (max-width: 576px) {
            max-width: 15px;
          }
        }
        svg {
          width: 20px;
          height: 20px;
          fill: var(--main-color4);
          margin-left: 5px;
          @media (max-width: 576px) {
            width: 15px;
            height: 15px;
          }
        }
        @media (max-width: 576px) {
          font-size: 12px;
        }
      }
    }
  }
}
.form-register, .form-login {
  padding-top: 0 !important;
  .btn-create {
    max-width: 300px;
  }
}
</style>