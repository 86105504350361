<template>
  <div class="content-checkout">
    <div class="content-checkout-itens container">
      <div class="content-checkout-itens-header">
        <h1>Checkout</h1>
      </div>
      <form class="content-checkout-itens-body" ref="formCheckout" @submit.prevent="handleSubmit">
        <div class="row">
          <div class="col-lg-8">
            <!--  -->
            <form-login-or-register v-if="!user || (user && Object.keys(user).length == 0)">              
            </form-login-or-register>
            <!--  -->
            <form-shipping @changeFormReadonly="formReadonly = $event" :formReadonly="formReadonly" v-if="user && Object.keys(user).length > 0"></form-shipping>
            <!--  -->
            <form-payment :formReadonly="formReadonly" v-if="user && Object.keys(user).length > 0"></form-payment>
            <!--  -->
          </div>
          <div class="col-lg-4">
            <div class="content-resume" :class="{ 'content-resume-disabled': !user || (user && Object.keys(user).length == 0) || formReadonly }">
              <div class="content-resume-header">
                <h2>{{ $t('checkout.resume') }}</h2>
              </div>
              <div class="content-resume-body">
                <div class="content-resume-body-information">
                  <div class="texts">
                    <p>{{ $t('checkout.total_items') }}:</p>
                    <p>{{ cartProducts.price_itens|money }}</p>
                  </div>
                  <div class="texts">
                    <p>{{ $t('checkout.transport') }}:</p>
                    <p>{{ cartProducts.price_delivery|money }}</p>
                  </div>                  
                  <div class="total">
                    <p>{{ $t('checkout.total_purchase') }}:</p>
                    <p>{{ cartProducts.price_total|money }}</p>
                  </div>
                </div>
                <div class="content-resume-body-check">
									<div class="start-field">
										<input
											type="checkbox"
											name="condition"
											value=""
											required
										/>
										<label>
                      {{ $t('register.accept_terms') }}
                      <a v-b-modal.modalTerms>{{ $t('register.terms_and_conditions') }}</a> 
                      {{ $t('register.and_the') }}
                      <a v-b-modal.modalPolity>{{ $t('register.privacy_policy') }}</a>
                    </label>
									</div>
                </div>
                <button type="submit" class="content-resume-body-btn">{{ $t('checkout.complete_purchase') }}</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
  import FormLoginOrRegister from './FormLoginOrRegister';
  import FormShipping from './FormShipping';
  import FormPayment from './FormPayment';
  import { mapGetters, mapActions } from 'vuex';

  export default {
    data() {
      return {
        formReadonly: true,
      }
    },
    components: {
      FormLoginOrRegister,
      FormShipping,
      FormPayment,
    },
    computed: {
      ...mapGetters("User", ["user"]),
      ...mapGetters("Cart", ["cartProducts"]),
    },
    methods: {
      ...mapActions('Cart', ['updateCartCheckout', 'checkout']),       
      async handleSubmit(){
          let formData = await this.$root.getAllData(this.$refs.formCheckout);

          formData.checkout = 1;
          
          if (this.$refs.formCheckout.checkValidity()) {
              this.checkout(formData).then(response => {
                this.$store.dispatch('setNotify', {text: 'Pedido salvo com sucesso', type: 'success'});
                  this.$router.push({name: 'MyOrdersDetails', params: {id: response.id}})
              })
          } else {
              this.$refs.formCheckout.reportValidity();
          }
      },
    },
  };
</script>

<style lang="scss">
.content-checkout {
  padding: 80px 0px;
  @media (max-width: 991px) {
    padding: 40px 0px;
  }
  &-itens {
    &-header {
      padding-bottom: 10px;
      border-bottom: 1px solid #ebebeb;
      h1 {
        font-size: 35px;
        font-family: "Font Bold";
        color: var(--main-color5);
        margin-bottom: 0;
        text-transform: uppercase;
      }
    }
    &-body {
      padding-top: 30px;
      .content-already-registered {
        margin-bottom: 55px;
        h2 {
          font-size: 35px;
          font-family: "Font Bold";
          color: var(--main-color5);
          margin-bottom: 30px;
          text-align: left;
					@media (max-width: 576px){
						font-size: 28px;
					}
        }
        &-btns {
          display: flex;
          align-items: center;
					@media (max-width: 576px){
						flex-direction: column;
						align-items: initial;
					}
        }
        &-login {
          height: 45px;
          width: 100%;
					max-width: 270px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: var(--main-color);
          border: 1px solid #ebebeb;
          font-size: 16px;
          font-family: "Font Bold";
          color: var(--main-color4);
          text-transform: uppercase;
          text-decoration: none;
          transition: 500ms;
          margin-right: 30px;
					@media (max-width: 576px){
						margin-right: 0px;
						margin-bottom: 30px;
					}
          &:hover,
          &:focus {
            cursor: pointer;
            opacity: 0.8;
          }
        }
        &-create {
          height: 45px;
          width: 100%;
					max-width: 270px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #ececec;
          border: 1px solid #ececec;
          font-size: 16px;
          font-family: "Font Bold";
          color: var(--main-color4);
          text-transform: uppercase;
          text-decoration: none;
          transition: 500ms;
          &:hover,
          &:focus {
            cursor: pointer;
            opacity: 0.8;
          }
        }
      }
      .content-elements {
        margin-bottom: 70px;
        @media (max-width: 991px) {
          margin-bottom: 30px;
        }
        &-header {
          text-align: left;
          margin-bottom: 30px;
          h2 {
            font-size: 35px;
            font-family: "Font Bold";
            color: var(--main-color5);
            margin-bottom: 0;
						@media (max-width: 576px){
							font-size: 28px;
						}
          }
        }
        &-body {
          .content-input {
            text-align: left;
            margin-bottom: 30px;
            label {
              display: block;
              font-size: 16px;
              font-family: "Font Regular";
              color: var(--main-color4);
              margin-bottom: 5px;
              line-height: 1;
            }
            input,select {
              height: 50px;
              width: 100%;
              border: 1px solid #ebebeb;
              background: #fff;
              outline: none;
              font-size: 16px;
              font-family: "Font Regular";
              color: var(--main-color4);
              padding-left: 20px;
              appearance: none;
              -moz-appearance: none;
              -webkit-appearance: none;
            }
            &-select {
              position: relative;
              img {
                position: absolute;
                right: 20px;
                top: 50%;
                transform: translateY(-50%);
                pointer-events: none;
              }
            }
          }
          .start-field > input[type="checkbox"]:checked:before {
            content: "";
            height: 100%;
            width: 100%;
            background: url("../../../../assets/img/checkout/check.png");
            background-position: center !important;
            background-repeat: no-repeat !important;
          }
          .start-field > input[type="checkbox"]:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background-color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 0px;
            border: 0;
          }
          .start-field > input[type="checkbox"] {
            position: relative;
            height: 30px;
            width: 30px;
            border-radius: 0px;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            outline: 0 !important;
            margin: 0;
						margin-right: 20px;
          }
          .start-field {
            display: flex;
            align-items: center;
						label {
							margin-bottom: 0;
						}
          }
					.content-payment {
						display: flex;
						align-items: center;
						a {
							height: 180px;
							width: 180px;
							display: flex;
							justify-content: center;
							align-items: center;
							border: 1px solid #EBEBEB;
							transition: 500ms;
							&:not(:last-child){
								margin-right: 50px;
								@media (max-width: 576px){
									margin-right: 20px;
								}
							}
							&:hover,&:focus {
								cursor: pointer;
								border: 1px solid #000;
							}
						}
						.active {
							border: 1px solid #000;
						}
					}
        }
      }
			.btn-continue-shopping {
				display: flex;
				align-items: center;
				background: 0;
				border: 0;
				font-size: 16px;
				font-family: 'Font Bold';
				color: var(--main-color4);
				padding: 0;
        text-decoration: none;
				transition: 500ms;
				&:hover,&:focus {
					cursor: pointer;
					opacity: 0.8;
				}
				img {
					margin-right: 5px;
				}
			}
			.content-resume {
				padding: 40px 30px;
				background: var(--main-color4);
        position: sticky;
        top: 120px;
        transition: 500ms;
        @media (min-width: 992px) and (max-height: 800px) {
		      top: 100px;
	      }
				@media (max-width: 991px){
					margin-top: 30px;
          position: relative;
          top: initial;
				}
				&-header {
					padding-bottom: 20px;
					border-bottom: 1px solid var(--main-color);
					text-align: left;
					h2 {
						font-size: 25px;
						font-family: 'Font Bold';
						color: var(--main-color);
						margin-bottom: 0;
						text-transform: uppercase;
					}
				}
				&-body {
					&-information {
						padding: 30px 0px;
						border-bottom: 1px solid #fff;
						.texts {
							display: flex;
							align-items: center;
							justify-content: space-between;
							margin-bottom: 10px;
							p {
								font-size: 20px;
								font-family: 'Font Regular';
								color: var(--main-color);
								margin-bottom: 0;
							}
						}
						.total {
							display: flex;
							align-items: center;
							justify-content: space-between;
							p {
								font-size: 20px;
								font-family: 'Font Bold';
								color: var(--main-color);
								margin-bottom: 0;
							}
						}
					}
					&-btn {
						height: 55px;
						width: 100%;
						max-width: 310px;
						background: var(--main-color6);
						border: 1px solid var(--main-color6);
						display: flex;
						justify-content: center;
						align-items: center;
            text-decoration: none !important;
						font-size: 20px;
						font-family: 'Font Bold';
						color: var(--main-color);
						text-transform: uppercase;
						margin-top: 30px;
						transition: 500ms;
						&:hover,&:focus {
							background: var(--main-color);
							color: var(--main-color6);
						}
						@media (min-width: 992px) and (max-width: 1199px){
							font-size: 16px;
							height: 50px;
						}
						@media (max-width: 359px){
							font-size: 16px;
							height: 45px;
						}
					}
					&-check {
						margin-top: 30px;
						.start-field > input[type="checkbox"]:checked:before {
							content: "";
							height: 100%;
							width: 100%;
							background: url("../../../../assets/img/checkout/check.png");
							background-position: center !important;
							background-repeat: no-repeat !important;
							background-color: #fff;
						}
						.start-field > input[type="checkbox"]:before {
							content: "";
							position: absolute;
							top: 0;
							left: 0;
							bottom: 0;
							right: 0;
							background-color: #fff;
							display: flex;
							justify-content: center;
							align-items: center;
							border-radius: 0px;
							border: 0;
						}
						.start-field > input[type="checkbox"] {
							position: relative;
							height: 30px;
							width: 30px;
							border-radius: 0px;
							-webkit-appearance: none;
							-moz-appearance: none;
							appearance: none;
							outline: 0 !important;
							margin: 0;
							margin-right: 20px;
						}
						.start-field {
							display: flex;
							align-items: center;
							label {
								margin-bottom: 0;
								font-size: 16px;
								font-family: 'Font Regular';
								color: #fff;
								width: calc(100% - 50px);
								text-align: left;
								line-height: 1.2;
								a {
									text-decoration: underline;
									text-decoration-color: #fff !important;
									transition: 500ms;
									// margin: 0px 7px;
									&:hover,&:focus {
										cursor: pointer;
										opacity: 0.8;
									}
								}
							}
						}
					}
				}
			}
      .content-resume-disabled {
        opacity: 0.6;
        pointer-events: none;
      }
    }
  }
}
</style>