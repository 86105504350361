<template>
	<div>
		<div class="row" align="center">
			<input type="hidden" name="payment_type_id" v-model="payment_type_id">
			<template v-if="cartProducts.type_delivery == 'take_away'">
				<div v-for="(money) in paymentUnit" 
					:key="money.id"
					class="col-lg-3">
					<a @click="selectPayment(money.type)" 
					:class="{'btn-click btn-hover': true, 'btn-click-active': (payment_type_id == money.type.id)}" 
					href="javascript:;">
						<img class="img-fluid" :src="money.type.logo_path" style="height: 70px">
						<p>{{ money.type.name }}</p>
					</a>
				</div>
			</template>
			<template>
				<div v-for="(payment) in paymentFilter" 
				:key="payment.id"
				class="col-lg-3">
					<div>
						<a @click="selectPayment(payment.type)"
						:class="{'btn-click btn-hover': true, 'btn-click-active': (payment_type_id == payment.type.id)}" 
						href="javascript:;">
							<img class="img-fluid" :src="payment.type.logo_path">
							<!-- <p>{{ payment.type.name }}</p> -->
						</a>					
					</div>
				</div>	
			</template>
		</div>
		<!--  -->
		<div v-if="paymentSelected.slug == 'mbway'" class="row">
			<div class="col-lg-12">
		        <div class="row">
		          <div class="col-lg-6">
		            <div class="content-input">
		              <label>{{ $t('register.telphone') }}*</label>
		              <input
		                type="text"
		                name="mobile"
		                v-model="mobile"
		                required
		              />
		            </div>
		          </div>	          
		           <div class="col-lg-12">
		            <div class="content-input">
		              <div class="start-field">
		                <input type="checkbox" @click="stateCheckbox" />
		                <label>{{ $t('checkout.user_registry_information') }}</label>
		              </div>
		            </div>
		          </div>
		        </div>		        
		   </div>
		</div>
		<div v-else-if="paymentSelected.slug == 'deposito'" class="row">
			<div class="col-lg-12" v-if="paymentSelected.configurations.banks.length > 0">
				<div class="row">
					<div class="col-lg-6" v-for="(item, itemIndex) in paymentSelected.configurations.banks" :key="itemIndex">
						<div class="content-input" v-for="value in item.data" :key="value.field">
							<label>{{ value.field }}</label>
							<input type="text" name="deposito" :value="value.value" disabled />
						</div>
					</div>	          
				</div>		        
		  </div>
		</div>
		<!--  -->
	</div>
	
</template>
<script>
	import {mapGetters} from 'vuex';

	export default{
		computed: {
			...mapGetters('SiteConfigs', ['configs']),
			...mapGetters('Cart', ['cartProducts']),
			...mapGetters('User', ['user']),
    	// ...mapGetters("Order", ["order", "loading"]),
			paymentFilter(){
				let data = [];

				if(Object.keys(this.configs).length > 0){
					this.configs.payments.map((value, index) => {					
						//pagamento na retirada
						if(value.type_id != 2 && this.cartProducts.price_total >= value.price_min){
							data.push(value);
						}
					});
				}
				
				return data;
			},
			paymentUnit(){
				let data = [];
				if(Object.keys(this.configs).length > 0){
					this.configs.payments.map((value, index) => {					
						//pagamento na retirada
						if(value.type_id == 2  && this.cartProducts.price_total >= value.price_min){
							data.push(value);
						}
					});
				}

				return data;
			},
		},
		methods: {
			selectPayment(value){
				this.payment_type_id = value.id;
				this.paymentSelected = value;
			},
			stateCheckbox($event){
		        if($event.target.checked){
		          this.mobile = this.user.mobile;		          
		        }else{
		          this.mobile = '';		          
		        }
		  }
		},
		data(){
			return{
				paymentSelected: {},
				payment_type_id: '',
				mobile: '',
			}
		}
	}
</script>
<style lang="scss" scoped>
	.btn-click{
		width: 100%;
		background-color: #f9f9f9  !important;	    
		display: flex !important;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		font-weight: 700 !important;
		letter-spacing: 1px !important;
		line-height: 1 !important;
		padding: 18px 20px !important;
		text-align: center !important;
		text-transform: uppercase !important;
		border-radius: 5px !important;
		z-index: 9 !important;
		height: 110px;
		border: 1px solid transparent;
		margin-bottom: 30px;
		text-decoration: none;
		transition: 500ms;
		// img {
		// 	max-width: 100px;
		// }
		&:hover,&:focus {
			border: 1px solid #000;
		}
		p {
			font-size: 16px;
			font-family: Font Bold;
			color: var(--main-color4);
			padding: 0;
			text-decoration: none;
			margin-top: 10px;
			margin-bottom: 0;
			text-transform: none;
		}
	}
	.btn-click-active{
		border: 1px solid #000;
	}
</style>