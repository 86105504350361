<template>
	<div :class="{'desactive-form': formReadonly}">
		<div class="content-elements">
      <div class="content-elements-header">
        <h2>{{ $t('checkout.invoice_data') }}</h2>
      </div>
      <div class="content-elements-body">
        <div class="row">
          <div class="col-lg-6">
            <div class="content-input">
              <label>{{ $t('checkout.name') }}*</label>
              <input
                type="text"
                name="billing_name"
                v-model="form.billing_name"
                required
              />
            </div>
          </div>
          <div class="col-lg-6">
            <div class="content-input">
              <label>NIF/NIPC</label>
              <input
                type="text"
                name="billing_document"
                v-model="form.billing_document"                    
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="content-input">
              <div class="start-field">
                <input type="checkbox" name="user-delivery" value="" @click="stateCheckbox" />
                <label>{{ $t('checkout.user_registry_information') }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content-elements">
      <div class="content-elements-header">
        <h2>{{ $t('checkout.payment') }}</h2>
      </div>
      <div class="content-elements-body">
        <payment-list></payment-list>
      </div>
    </div>
	</div>
</template>
<script>
  import PaymentList from './PaymentList';
  import {mapGetters} from 'vuex';

  export default{
    components:{
      PaymentList
    },
    props: {
      formReadonly: Boolean
    },
    computed: {
      ...mapGetters('User', ['user']),      
      ...mapGetters('Cart', ['cartProducts']),      
    },
    methods: {
      stateCheckbox($event){        
        if($event.target.checked){
          this.form.billing_name = this.user.name;
          this.form.billing_document = this.user.document;
        }else{
          this.form.billing_name = '';
          this.form.billing_document = '';
        }
      }
    },
    data(){
      return{
        form: {
          billing_name: '',
          billing_document: '',
        }
      }
    }
  }
</script>