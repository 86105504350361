<template>
  <div class="content-elements">
    <div class="content-elements-header">
      <h2>Tipo de entrega</h2>
    </div>
    <div class="content-elements-body types-delivery">
      <div class="row">
        <div class="col-lg-12">
          <div class="content-elements-body-types-delivery">
            <div class="start-field">
              <input id="types_deliverys-1" type="radio" name="types_deliverys" value="take_away" @click="checkTypeDelivery($event)" :checked="this.typeDeliverys == 'take_away'">
              <label for="types_deliverys-1">Entrega na loja</label>
            </div>
            <div class="start-field">
              <input id="types_deliverys-2" type="radio" name="types_deliverys" value="region" @click="checkTypeDelivery($event)" :checked="this.typeDeliverys == 'region'">
              <label for="types_deliverys-2">Entrega na morada</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template v-if="this.typeDeliverys == 'take_away'">
      <div class="content-elements-body">
        <div class="row">
          <div class="col-lg-6">
            <div class="content-input mb-0">
              <label>Selecionar Loja</label>
              <div class="content-input-select">
                <select
                  name="unit_id"
                  v-model="form.unit_id"
                  @change="selectShop"
                  required
                >
                  <option value="">---</option>
                  <option
                    v-for="(unit, unitIndex) in this.takeAway.units"
                    :key="unitIndex"
                    :value="unit.id"
                  >
                    {{ unit.name }}
                  </option>         
                </select>
                <img
                  class="img-fluid"
                  src="@/assets/img/arrow-down.png"
                  alt="icon arrow down"
                  title="icon arrow down"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="this.typeDeliverys == 'region'">
      <div class="content-elements-header">
        <h2>{{ $t('checkout.delivery_address') }}</h2>
      </div>
      <div class="content-elements-body">
        <div class="row">
          <div class="col-lg-6">
            <div class="content-input">
              <label>{{ $t('checkout.postal_code') }}*</label>
              <the-mask
                :mask="['####-###']"
                name="postal_code"
                :value="cartProducts.postal_code"
                @input="onInput"
                required
              />
            </div>
          </div>
          <div class="col-lg-6">
            <!--  -->
            <shipping-options></shipping-options>
            <!--  -->
          </div>
          <div class="col-lg-12" :class="{'desactive-form': formReadonly}">
            <div class="content-input">
              <label>{{ $t('checkout.household') }}*</label>
              <input
                type="text"
                name="address"
                value=""
                required
                :readonly="formReadonly"
              />
            </div>
          </div>
        </div>
        <div class="row" :class="{'desactive-form': formReadonly}">
          <div class="col-lg-6">
            <div class="content-input">
              <label>{{ $t('checkout.number') }}*</label>
              <input
                type="text"
                name="address_number"
                required
                :readonly="formReadonly"
              />
            </div>
          </div>
          <!-- <div class="col-lg-4">
            <div class="content-input">
              <label>{{ $t('checkout.reference') }}*</label>
              <input
                type="text"
                name="address_reference"
                required
                :readonly="formReadonly"
              />
            </div>
          </div> -->
          <div class="col-lg-6">
            <div class="content-input">
              <label>{{ $t('checkout.complement') }}</label>
              <input
                type="text"
                name="address_complement"
                :readonly="formReadonly"
              />
            </div>
          </div>
        </div>
        <div class="row" :class="{'desactive-form': formReadonly}">
          <div class="col-lg-6">
            <div class="content-input">
              <label>{{ $t('checkout.district') }}*</label>
              <div class="content-input-select">
                <select
                  name="state_id"
                  v-model="form.state_id"
                  @change="getCities"
                  required
                >
                  <option value="">---</option>
                  <option
                    v-for="(opt, optIndex) in states"
                    :key="optIndex"
                    :value="opt.id"
                  >
                    {{ opt.name }}
                  </option>
                </select>
                <img
                  class="img-fluid"
                  src="@/assets/img/arrow-down.png"
                  alt="icon arrow down"
                  title="icon arrow down"
                />
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="content-input">
              <label>{{ $t('checkout.county') }}*</label>
              <div class="content-input-select">
                <select name="city_id" v-model="form.city_id" required>
                  <option value="">---</option>
                  <option
                    v-for="(opt, optIndex) in cities"
                    :key="optIndex"
                    :value="opt.id"
                  >
                    {{ opt.name }}
                  </option>
                </select>
                <img
                  class="img-fluid"
                  src="@/assets/img/arrow-down.png"
                  alt="icon arrow down"
                  title="icon arrow down"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import ShippingOptions from "./ShippingOptions";
import { TheMask } from "vue-the-mask";
import { mapGetters, mapActions } from "vuex";
import axios from "axios";

export default {
  components: {
    TheMask,
    ShippingOptions,
  },
  props: {
    formReadonly: Boolean
  },
  computed: {
    ...mapGetters("Cart", ["cartProducts"]),
  },
  methods: {
    ...mapActions("Cart", ["updateCartCheckout"]),
    onInput(value) {
      if (value.length == 7) {
        this.calculateDelivery(value);
      }
    },
    calculateDelivery(postal_code, typeDelivery) {
      let params = {};
      if (typeDelivery && typeDelivery == 'take_away'){
        params.type_delivery = typeDelivery;
        params.delivery_service = "";
      }
      params.postal_code = postal_code;

      this.updateCartCheckout(params).then((response) => {
        // CALL UNITS//
        this.units();
        //////////////
        if (
          response.response_deliveries &&
          response.response_deliveries.length > 0
        ) {
          // SIMULATE CLICK INPUT RÁDIO
          let element = document.getElementById('Transporte0');
          element.click();
          ////////////////////////////

          this.formReadonly = false;
          this.$emit('changeFormReadonly', false);
        } else {
          this.formReadonly = true;
          this.$emit('changeFormReadonly', true);
        }
      });
    },
    getStates() {
      axios
        .get("/api/v1/customer/get-states", { params: { country: "PT" } })
        .then(
          function (response) {
            this.states = response.data.data;
          }.bind(this)
        );
    },
    getCities() {
      axios
        .get("/api/v1/customer/get-cities", {
          params: {
            state_id: this.form.state_id,
          },
        })
        .then(
          function (response) {
            this.cities = response.data.data;
          }.bind(this)
        );
    },
    checkTypeDelivery($event){
      if ($event.target.value == 'region'){
        this.typeDeliverys = 'region';
      } else if ($event.target.value == 'take_away') {
        this.typeDeliverys = 'take_away';
        this.calculateDelivery(this.postalCode, this.typeDeliverys);
        this.form.unit_id = "";
      }
    },
    units() {
      for (let n in this.cartProducts.response_deliveries){
        if (this.cartProducts.response_deliveries[n].slug == 'take_away'){
          this.takeAway = this.cartProducts.response_deliveries[n];
        }
      }
    },
    selectShop() {
      if (this.form.unit_id !== ""){
        this.$emit('changeFormReadonly', false);
      } else {
        this.$emit('changeFormReadonly', true);
      }
    }
  },
  mounted() {
    this.calculateDelivery(this.postalCode, this.typeDeliverys);
    this.getStates();
  },
  data() {
    return {
      states: [],
      cities: [],
      // formReadonly: true,
      form: {
        state_id: "",
        city_id: "",
        unit_id: "",
      },
      typeDeliverys: 'take_away',
      postalCode: '0000000',
      takeAway: {},
    };
  },
};
</script>

<style lang="scss">
.desactive-form {
  opacity: 0.6;
  pointer-events: none;
}
.types-delivery {
  margin-bottom: 15px;
}
.content-elements-body-types-delivery {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  .start-field {
    display: flex;
    &:first-child {
      margin-right: 80px;
    }
    [type="radio"]:checked,
    [type="radio"]:not(:checked) {
      position: absolute;
      left: 3px;
      opacity: 0;
    }
    [type="radio"]:checked + label,
    [type="radio"]:not(:checked) + label {
      position: relative;
      padding-left: 40px;
			height: 32px;
      cursor: pointer;
      display: flex;
			align-items: center;
			font-size: 16px;
			font-family: 'Font Regular';
			color: var(--main-color5);
			margin-bottom: 0px;
			line-height: 1;
			span {
				font-family: 'Font Bold';
				margin-left: 5px;
			}
    }
    [type="radio"]:checked + label:before,
    [type="radio"]:not(:checked) + label:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 32px;
      height: 32px;
      border-radius: 100%;
      background: #fff;
      border: 1px solid #EBEBEB;
    }
    [type="radio"]:checked + label:after,
    [type="radio"]:not(:checked) + label:after {
      content: "";
      width: 18px;
      height: 18px;
      background: var(--main-color5);
      position: absolute;
      top: 7px;
      left: 7px;
      border-radius: 100%;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
    }
    [type="radio"]:not(:checked) + label:after {
      opacity: 0;
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    [type="radio"]:checked + label:after {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
}
</style>