<template>
  <div>
    <ul class="content-transport" v-if="cartProducts.postal_code">
      <template v-if="cartProducts.response_deliveries.length > 0">
        <h2> {{ $t('checkout.select_the_shipping') }}:</h2>
        <li
          class="content-transport-elements"
          v-for="(delivery, deliveryIndex) in cartProducts.response_deliveries"
          :key="deliveryIndex"
        >
          <template v-if="delivery.slug !== 'take_away'">
            <input
              type="radio"
              name="delivery_type"
              :id="delivery.name+deliveryIndex"
              @click="typeDelivery(delivery)"
              :checked="checkIfChecked(delivery)"
              required
            />
            <label :for="delivery.name+deliveryIndex">
              {{ delivery.name }}
              <span v-if="delivery.delay">({{ delivery.delay }} dias) </span>
              <span>{{ delivery.price | money }}</span> 
            </label>
          </template>
        </li>
        <template>
          <p class="content-transport-bold"> Os valores expostos correspondem a 2 dias de entregas diferentes:</p>
          <p>Lisboa, Odivelas e Loures > 4ª feira à tarde</p>
          <p>Oeiras, Cascais e Amadora > 5ª feira à tarde</p>
          <p>Entregas por correio registado sob consulta</p>
        </template>
      </template>
      <template v-else>
        <li class="content-transport-elements">
          {{ $t('checkout.not_delivery') }}
        </li>
      </template>
    </ul>
    <ul class="content-transport" v-else>
      <li class="content-transport-elements">{{ $t('checkout.enter_the_postal_code') }}</li>
    </ul>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
  },
  computed: {
    ...mapGetters("Cart", ["cartProductsTotal", "cartProducts"]),
  },
  methods: {
    ...mapActions("Cart", ["updateCartCheckout"]),
    typeDelivery(delivery) {
      let params = {};
      params.type_delivery = delivery.slug;
      params.delivery_service =
        "service_code" in delivery ? delivery.service_code : "";
      this.updateCartCheckout(params);
    },
    checkIfChecked(delivery) {
      if ("service_code" in delivery) {
        if (delivery.service_code == this.cartProducts.delivery_service) {
          return true;
        }
      } else if (delivery.slug == this.cartProducts.type_delivery) {
        return true;
      }
      
      return false;
    },
  },
};
</script>
<style lang="scss" scoped>
.content-transport {
  list-style: none;
	margin-bottom: 0;
	background: var(--main-color5);
	padding: 20px;
  @media (max-width: 991px){
    margin-bottom: 30px;
  }
	h2 {
		font-size: 16px;
    font-family: "Font Regular";
    color: var(--main-color);
    margin-bottom: 10px;
    line-height: 1;
		text-align: left;
	}
  p {
    font-size: 14px;
    font-family: 'Font Regular';
    color: var(--main-color);
    margin-bottom: 2px;
    text-align: left;
  }
  &-bold {
    font-family: 'Font Bold' !important;
  }
  &-elements {
		display: flex;
		align-items: center;
    position: relative;
		text-align: left;
    margin-bottom: 10px;
		font-size: 16px;
		font-family: 'Font Regular';
		color: var(--main-color);
    [type="radio"]:checked,
    [type="radio"]:not(:checked) {
      position: absolute;
      left: 3px;
      opacity: 0;
    }
    [type="radio"]:checked + label,
    [type="radio"]:not(:checked) + label {
      position: relative;
      padding-left: 30px;
			padding-top: 3px;
      cursor: pointer;
      display: flex;
			align-items: center;
			font-size: 16px;
			font-family: 'Font Regular';
			color: var(--main-color);
			margin-bottom: 0px;
			line-height: 1;
			span {
				font-family: 'Font Bold';
				margin-left: 5px;
			}
    }
    [type="radio"]:checked + label:before,
    [type="radio"]:not(:checked) + label:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 20px;
      height: 20px;
      border-radius: 100%;
      background: #fff;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    }
    [type="radio"]:checked + label:after,
    [type="radio"]:not(:checked) + label:after {
      content: "";
      width: 15px;
      height: 15px;
      background: url("../../../../assets/img/checkout/check.png");
      background-position: center !important;
      background-repeat: no-repeat !important;
      position: absolute;
      top: 4px;
      left: 3px;
      border-radius: 100%;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
    }
    [type="radio"]:not(:checked) + label:after {
      opacity: 0;
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    [type="radio"]:checked + label:after {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
}
</style>