<template>
  <form class="content-login-itens-body">
    <div class="row">
      <div class="content-elements">
        <div class="content-elements-header">
          <h2>{{ $t('register.login_information') }}</h2>
        </div>
        <form
          ref="formLogin"
          @submit.prevent="handleSubmit"
          method="post"
          class="content-elements-body"
        >
          <div class="row">
            <div class="col-lg-12">
              <div class="content-input">
                <label>{{ $t('register.email_address') }} *</label>
                <input type="text" name="login" placeholder="" required />
              </div>
            </div>
            <div class="col-lg-12">
              <div class="content-input">
                <label>{{ $t('register.password') }}*</label>
                <input
                  type="password"
                  name="password"
                  placeholder=""
                  required
                />
              </div>
            </div>
            <div class="col-lg-12">
              <button type="submit" class="btn-create" >{{ $t('register.login') }}</button>
            </div>
            <div class="col-lg-12">
              <div class="btns-div">
                <a @click="targetPage()" class="btn-continue-shopping">
                  <img
                    class="img-fluid"
                    src="@/assets/img/arrow-left.png"
                    alt="arrow left"
                    title="arrow left"
                  />
                  {{ $t('btns_default.create_account') }}
                </a>
                <router-link
                  :to="{ name: 'RecuperyPassword' }"
                  class="btn-continue-shopping"
                >
                  {{ $t('btns_default.forgot_password') }}
                </router-link>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </form>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "FormLogin",
  metaInfo: {
    title: "FormLogin",
  },
  methods: {
    ...mapActions("User", ["login"]),
    async handleSubmit() {
      let formData = await this.$root.getAllData(this.$refs.formLogin);

      formData.birthday = this.birthday;

      if (this.$refs.formLogin.checkValidity()) {
        this.login(formData).then((response) => {
          this.$store.dispatch("setNotify", {
            text: "Seja bem vindo novamente " + response.name,
          });
          localStorage.setItem("must_token", response.token);

          if (this.$route.name != 'cart-checkout') {
            this.$router.push({ name: "Home" });
          }
        });
      } else {
        this.$refs.formLogin.reportValidity();
      }
    },
    targetPage() {
      if(this.$route.name == 'cart-checkout'){
        this.$emit('CreateAccount', 1);
      } else {
        this.$router.push({name: 'Register' });
      }
    }
  },
};
</script>